


























import Vue from "vue";

export default Vue.extend({
  data: () => ({
    phone: "+79663134394",
    code: "",
    phone_code_hash: "",
  }),

  methods: {
    submit() {
      this.$mtproto
        .call("auth.signIn", {
          phone_code: this.code,
          phone_number: this.phone,
          phone_code_hash: this.phone_code_hash,
        })
        .then(() => {
          this.$router.push("/");
        });
    },
    getCode() {
      this.$mtproto
        .call("auth.sendCode", {
          phone_number: this.phone,
          settings: {
            _: "codeSettings",
          },
        })
        .then((result) => {
          this.phone_code_hash = result.phone_code_hash;
        });
    },
  },
});
